<template>
  <!-- NAME[epic=绩效] 超期未拜访统计 -->
  <div class="orderTest-container">
    <el-form
      ref="form"
      :model="form"
      inline
      label-position="right"
      label-width="120px"
    >
      <el-form-item label="" prop="user_id">
        <el-select
          v-model="form.user_id"
          clearable
          style="width: 140px"
          placeholder="业务员"
        >
          <el-option
            v-for="(i, idx) in staffList"
            :key="idx"
            :value="i.user_id"
            :label="i.user_name"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-select
          v-model="form.type"
          clearable
          style="width: 140px"
          placeholder="未拜访天数"
        >
          <el-option
            v-for="(i, idx) in typeSelect"
            :key="idx"
            :value="i.id"
            :label="i.name"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-input
          v-model.number="form.days"
          style="width: 140px"
          placeholder="天数"
        >
          <template slot="append">天</template>
        </el-input>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-select
          v-model="form.line_id"
          clearable
          style="width: 140px"
          placeholder="线路"
        >
          <el-option
            v-for="(i, idx) in lineSelect"
            :key="idx"
            :value="i.id"
            :label="i.line_name"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-button type="primary" @click="fetchData">查询</el-button>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-button @click="handleExport">导出</el-button>
      </el-form-item>
    </el-form>

    <el-table v-loading="loading" :data="list">
      <el-table-column
        v-for="(item, tableIndex) in columns"
        :key="tableIndex"
        :prop="item.prop"
        :label="item.label"
        :width="item.width"
        align="center"
      ></el-table-column>
    </el-table>
    <el-pagination
      :current-page="form.pageNo"
      :layout="layout"
      :page-size="form.pageSize"
      :total="total"
      background
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    ></el-pagination>
  </div>
</template>
<script>
  import { downloadFile, postAction } from '@/api/Employee'
  import { clientLineList } from '@/api/ClientDetail' // 线路下拉

  export default {
    name: 'VisitLog',
    components: {},
    data() {
      return {
        loading: false,
        layout: 'total, sizes, prev, pager, next, jumper',
        form: {
          user_id: '',
          type: 1,
          days: '',
          line_id: '',
          pageSize: 10,
          pageNo: 1,
        },
        staffList: [],
        // 类型   0全部类型 1未拜访天数   2未拜访天数    3未拜访天数
        typeSelect: [
          {
            id: 1,
            name: '未拜访天数>=',
          },
          {
            id: 2,
            name: '未拜访天数<=',
          },
          {
            id: 3,
            name: '未拜访天数=',
          },
        ],
        lineSelect: [],
        total: 0,
        list: [],
        columns: [
          {
            order: 1,
            label: '业务员',
            prop: 'user_name',
            width: '',
          },
          {
            order: 2,
            label: '客户名称',
            prop: 'cust_name',
            width: '',
          },
          {
            order: 3,
            label: '联系人',
            prop: 'boss',
            width: '350px',
          },
          {
            order: 4,
            label: '未拜访天数(天)',
            prop: 'visit_day',
            width: '',
          },
          {
            order: 5,
            label: '最近拜访',
            prop: 'last_visit_time',
            width: '',
          },
        ],
      }
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {
      // 线路下拉
      clientLineList().then((res) => {
        if (res.code == 200) this.lineSelect = res.data
      })
      // 获取业务员下拉
      postAction('/taskAdmin/visit/staff-list-by-role').then((res) => {
        if (res.code == 200) this.staffList = res.data
      })
    },
    methods: {
      async fetchData() {
        // 长期未拜访列表
        try {
          this.loading = true
          let { data, totalCount } = await postAction(
            '/taskAdmin/visit/un-visit-list',
            this.form
          )
          this.list = data
          this.total = totalCount
          this.loading = false
        } catch (err) {
          this.loading = false
        }
      },
      handleCurrentChange(val) {
        this.form.pageNo = val
        this.fetchData()
      },
      handleSizeChange(val) {
        this.form.pageSize = val
        this.fetchData()
      },
      handleExport() {
        downloadFile(
          '/taskAdmin/visit/un-visit-list-export',
          '长期未拜访.xlsx',
          this.form
        )
      },
    },
  }
</script>
<style lang="scss" scoped></style>
